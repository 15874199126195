.containers-box{
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px; /* corresponds to gap: 2 in MUI spacing system */
}
@media (max-width: 768px) {
  .breadcrumbs-mobile-text {
    font-size: 10px; /* Adjust the font size as needed */
  }
}
@media (max-width: 768px) {
  .logo-in-page-container
  {
    display: none; /* Adjust the font size as needed */
  }
}

.typographyClass {
  margin: 8px; /* corresponds to m={1} */
  text-align: center;
  color: #1434A4;
  font-weight: bold;
}
.billing-container {
  display: flex;
  flex-direction: column;
  
}
.side-nav {
  width: 95%;
  border-right: 1px solid #e0e0e0;
  min-height: 50px;
}

/* Style for desktops and above */
@media (min-width: 768px) {
  .billing-container {
    flex-direction: row;
  }
  .side-nav {
    width: 200px;
    border-right: 1px solid #e0e0e0;
    min-height: 550px;
  }
}


@media (max-width: 768px) {
  .serviceOverViewMobile{
    display: none;
  }
}

.mainContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  overflow: auto;
}



.textfield-container {
  width: 100%;
  margin-bottom: 16px; /* Example margin */
}
.category-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .category-container {
    display: flex;
    flex-direction: row;
  }
  .textfield-container {
    width: 250px;
  }  
}
@media (max-width:768px){
  .mobilePaddingTop{
    margin-top: 20px;
  }
}
@media (min-width: 960px) {
  .category-container {
    display: flex;
    flex-direction: row;
    /* Adjust styles for md screens */
  }
}

@media (min-width: 1280px) {
  .category-container {
    display: flex;
    flex-direction: row;
    /* Adjust styles for lg screens */
  }
}






.mobileScrollTabs {
  overflow-x: scroll; /* Enable horizontal scrolling on small screens */
  display: flex;
  flex-wrap: nowrap; /* Ensure tabs stay in a single line */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: auto; /* Hide scrollbar */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}
.centeredBox {
  background-color: #F5F5F5;
  box-shadow: 0px -4px 4px rgba(1, 0, 0, 0.25);
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
}
.mobileScrollTabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, Edge */
}
.gridItem1 {
  margin-right: 150px;
}

@media (min-width: 600px) {
  .gridItem2 {
    margin-right: 22px; /* sm */
  }
}

@media (min-width: 960px) {
  .gridItem2 {
    margin-right: 20px; /* md, lg */
  }
}

@media (max-width: 599.95px) {
  .gridItem3 {
    margin-top: 2px; /* xs */
  }
}

@media (min-width: 600px) {
  .gridItem3 {
    margin-top: 0; /* sm */
  }
}

@media (min-width: 960px) {
  .gridItem3 {
    margin-top: 0; /* md, lg */
  }
}

@media (max-width: 599.95px) {
  .gridItem4 {
    margin-top: 2px; /* xs */
  }
}

@media (min-width: 600px) {
  .gridItem4 {
    margin-top: 0; /* sm */
  }
}

@media (min-width: 960px) {
  .gridItem4 {
    margin-top: 0; /* md, lg */
  }
}

/* gridstyling.css */
.text-right {
    text-align: right;
    margin-right: 5px;
  }
  /* DueDatesThisWeek.css */

.styled-task-box {
  position: relative;
  height: 100%;
  padding: 16px; /* equivalent to padding: 2 in MUI */
}

@media (min-width: 960px) {
  .styled-task-box {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0;
  }
}

.task-box-main {
  display: flex;
  gap: 30px;
  align-items: flex-start; /* Changed from start to flex-start */
  flex-direction: column;
}

@media (min-width: 960px) {
  .task-box-main {
    flex-direction: row;
  }
}

/* Base styles for all devices */
.padding-left-responsive {
  padding-left: 48px; /* equivalent to paddingLeft: 6 in MUI */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .padding-left-responsive {
    padding-left: 48px; /* equivalent to paddingLeft: 5 in MUI */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .padding-left-responsive {
    padding-left: 32px; /* equivalent to paddingLeft: 4 in MUI */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .padding-left-responsive {
    padding-left: 24px; /* equivalent to paddingLeft: 3 in MUI */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .padding-left-responsive {
    padding-left: 0; /* Remove padding on extra large devices */
  }
}

 /* attendance checkin cheout appbar */
.checkInButton {
  font-size: 0.75rem; /* extra small screen font size */
  padding: 4px 8px; /* extra small screen padding */
}

@media (min-width: 600px) {
  .checkInButton {
    font-size: 0.875rem; /* small screen font size */
    padding: 8px 16px; /* small screen padding */
  }
}

@media (min-width: 960px) {
  .checkInButton {
    font-size: 1rem; /* medium screen font size */
    padding: 8px 16px; /* medium screen padding */
  }
}

@media (min-width: 1280px) {.checkoutButton {
  margin-top: 5px; /* default margin top */
  margin-left: 5px; /* default margin left */
  font-size: 0.875rem; /* default font size */
  padding: 8px 16px; /* default padding */
}

@media (min-width: 600px) {
  .checkoutButton {
    margin-top: 0; /* small screen margin top */
    margin-left: 10px; /* small screen margin left */
    font-size: 0.875rem; /* small screen font size */
    padding: 8px 16px; /* small screen padding */
  }
}

@media (min-width: 960px) {
  .checkoutButton {
    font-size: 1rem; /* medium screen font size */
    padding: 8px 16px; /* medium screen padding */
  }
}

@media (min-width: 1280px) {
  .checkoutButton {
    font-size: 1rem; /* large screen font size */
    padding: 4px 12px; /* large screen padding */
  }
}

.checkoutButton:disabled {
  opacity: 0.5; /* reduced opacity for disabled state */
  cursor: not-allowed; /* show not-allowed cursor for disabled state */
}

  .checkInButton {
    font-size: 1rem; /* large screen font size */
    padding: 4px 12px; /* large screen padding */
  }
}

.checkInButton:disabled {
  opacity: 0.5; /* reduced opacity for disabled state */
  cursor: not-allowed; /* show not-allowed cursor for disabled state */
}


.clientOverViewBox {
  padding-top: 24px; /* Default padding-top */
}

@media (max-width: 599px) {
  .clientOverViewBox {
    padding-top: 100px; /* padding-top for extra small devices (phones) */
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .clientOverViewBox {
    padding-top: 0; /* No padding-top for small devices (tablets) */
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .clientOverViewBox {
    padding-top: 0; /* No padding-top for medium devices */
  }
}

@media (min-width: 1280px) {
  .clientOverViewBox {
    padding-top: 0; /* No padding-top for large devices */
  }
}
.newEstimateContainer {
  max-height: 80vh;
  overflow: auto;
  padding: 20px;
}

.tooltipIcon {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}
.taskListViewContainer {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0;
}

.taskListViewContainerMobile {
  padding-top: 100px;
}

@media (min-width: 600px) {
  .taskListViewContainerMobile {
    padding-top: 0;
  }
}
.view-all-paddingtop-mobile{
  padding: 16px;
}
.view-all-paddingtop-service{
  padding: 0px;
}
@media (max-width: 600px) {
  .view-all-paddingtop-mobile {
    padding-top: 100px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 768px) {
  .view-all-paddingtop-service {
    padding-top: 60px;
    /* padding-left: 16px; */
    /* padding-right: 16px; */
  }
}

@media (min-width: 768px) {
  .view-all-paddingtop-service {
    padding-top: 20px; /* Adjust padding for desktop and above */
    /* Optionally adjust other padding properties if needed */
  }
}
@media (max-width: 768px) {
  .view-all-paddingtop-servicebox {
    padding-top: 20px; /* Adjust padding for desktop and above */
    /* Optionally adjust other padding properties if needed */
  }
}
@media (min-width: 768px) {
  .view-all-paddingtop-servicebox {
    padding: 20px; /* Adjust padding for desktop and above */
    padding-top: 0px; 
    /* Optionally adjust other padding properties if needed */
  }
}

/* .view-all-paddingtop-mobile-billing{
  padding: 16px;
} */
@media (max-width: 600px) {
  .view-all-paddingtop-mobile-billing {
    padding-top: 100px;
    /* padding-left: 16px;
    padding-right: 16px; */
  }
}

@media (max-width: 768px) {
  .view-all-paddingtop-mobile-task {
    padding-top: 100px;
    /* padding-left: 16px;
    padding-right: 16px; */
  }
}
.action-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.action-container > * {
  margin-right: 16px; /* Add margin-right to all children of .action-container */
}

@media (max-width: 600px) {
  .action-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .search-container {
    width: 100%; /* Full width on mobile */
    max-width: 200px; /* Adjust this value to decrease width */
  }
  
}

.attendance-styled-task-box {
  margin-top: 3rem;  /* Equivalent to mt={3} in MUI */
  height: 400px;
  overflow-x: auto;
}

/* Default styles for larger screens */
@media (min-width: 600px) {
  .filter-container {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
  }
  .filter-field {
    /* margin-bottom: 10px; */
    width: 50%;
}
}

/* Styles for smaller screens */
@media (max-width: 599px) {
  .filter-container {
      display: block;
  }
  .filter-field {
      margin-bottom: 10px;
      width: 70%;
  }
  .filter-field1 {
    margin-bottom: 10px;
    width: 100%;
}
}
/* TaskHeader.css */

/* TaskHeader.css */

.sticky-box {
  position: sticky;
  z-index: 2;
}

@media (max-width: 599.95px) {
  .sticky-box {
    top: 100px; /* Adjust top position for extra small screens */
    position: relative;
  }
}

@media (min-width: 600px) and (max-width: 959.95px) {
  .sticky-box {
    top: 50px; /* Adjust top position for small screens */
    position: relative;

  }
}

@media (min-width: 960px) and (max-width: 1279.95px) {
  .sticky-box {
    top: 50px; /* Adjust top position for medium screens */
  }
}

@media (min-width: 1280px) {
  .sticky-box {
    top: 50px; /* Adjust top position for large screens */
  }
}

.buttonWithMargin {
  /* Default styles for the button (if any) */

  /* Media query for mobile screens */
  @media (max-width: 768px) {
    margin-bottom: 48px; /* Equivalent to marginBottom: 6 in MUI spacing (6 * 8px) */
  }
}

.infoContainer {
  padding: 16px; /* Equivalent to p={3} in Material-UI */
  background-color: #FBF9F2;
  margin-top: 80px;
  display: column;
}

@media (min-width: 600px) {
  .infoContainer {
    margin-top: 0px;
  }
}
.no-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 80px 16px; 
  max-width: 550px;
  margin: auto;
  flex-direction: row; /* Default to row for larger screens */
}

@media (max-width: 600px) {
  .no-items-container {
    flex-direction: column; /* Change to column for mobile screens */
    padding: 0px 0px;

 
  }
}
.client-sticky-box {
  position: sticky;
  z-index: 2;
  background-color: white;
}

@media (max-width: 599.95px) {
  .client-sticky-box {
    top: 160px; /* Adjust top position for extra small screens */
    background-color: white;

  }
}

@media (min-width: 600px) and (max-width: 959.95px) {
  .client-sticky-box {
    top: 100px; /* Adjust top position for small screens */
    position: sticky;
  }
}

@media (min-width: 960px) and (max-width: 1279.95px) {
  .client-sticky-box {
    top: 50px; /* Adjust top position for medium screens */
  }
}

@media (min-width: 1280px) {
  .client-sticky-box {
    top: 50px; /* Adjust top position for large screens */
  }
}
/* styles.css */

.client-notification-container {
  display: flex;
  gap: 16px; /* Default gap size */
}

@media (min-width: 600px) {
  .client-notification-container {
    flex-direction: row;
    gap: 100px; /* Adjust gap size for small screens and up */
  }
}

@media (max-width: 599px) {
  .client-notification-container {
    flex-direction: column;
  }
}

@media (min-width: 960px) {
  .client-notification-container {
    flex-direction: row;
    gap: 100px;
  }
}

@media (min-width: 1280px) {
  .client-notification-container {
    flex-direction: row;
    gap: 100px;
  }
}
.hideOnMobile {
  display: none;  /* Hide on mobile */
}

@media (min-width: 600px) {
  .showOnDesktop {
    display: block;  /* Show on desktop (sm and above) */
  }

  .ml-1 {
    margin-left: 8px;  /* Adjust margin left as needed */
  }
}


.breadcrumbs-text {
  
  @media (max-width: 600px) {
    font-size: 10px; /* Decreased font size for mobile screens */
  }
}


.breadcrumbs-text1 {
  font-size: 14px; /* Default font size */

  @media (max-width: 600px) {
    font-size: 9px; /* Decreased font size for mobile screens */
  }
}

.eventCardBox {
  background-color: #F7F7F7;
  border-radius: 4px;
  padding: 16px; /* 16px corresponds to 2 * 8px */
  height: 100%;

  /* Mobile screen sizes */
  @media (max-width: 600px) {
    width: 250px; /* Default width */
  }
}

.eventCardLocationIcon {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.eventCardText {
  color: rgba(0, 0, 0, 0.7);
}

.eventCardFlex {
  display: flex;
  gap: 1px;
}

.eventCardContainer {
  display: flex;
  gap: 16px; /* Adjust the gap as needed */
}

/* Ensure the container stacks its items in a column on mobile */
@media (max-width: 600px) {
  .eventCardContainer {
flex-direction: column;  }
}


.nav-container {
  margin-bottom: 16px; /* Equivalent to mb={4} in MUI Box */
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (min-width: 0px) {
  .nav-container {
    padding-top: 100px;
    display: "flex",
    
  }
}

@media (min-width: 600px) {
  .nav-container {
    padding-top: 0px;
  }
}

@media (min-width: 960px) {
  .nav-container {
    padding-top: 0;
  }
}

.reports-nav-container {
  width: 200px;
  border-right: 1px solid #e0e0e0;
  min-height: 510px;
  height: 450px;
}

@media (max-width: 599px) {
  .reports-nav-container {
    padding-top: 50px; /* Equivalent to pt={5} in MUI Box for xs devices */
  }
}

@media (min-width: 600px) {
  .reports-nav-container {
    padding-top: 0; /* No padding top for sm, md, lg devices */
  }
}

.form-nav-container {
  width: 200px;
  border-right: 1px solid #e0e0e0;
  min-height: 550px;
}

@media (max-width: 599px) {
  .form-nav-container {
    padding-top: 80px; /* Equivalent to pt={13} in MUI Box for xs devices */
  }
}

@media (min-width: 600px) {
  .form-nav-container {
    padding-top: 0; /* No padding top for sm, md, lg devices */
  }
}

.task-box {
  position: relative;
  left: 0;
  right: 0;
  height: 100%;
}

css
Copy code
.date-picker-textfield {
  width: 100px; /* Default width */
  margin-left: 0; /* Default margin-left */
  margin-top: 10px; /* Default margin-top */

  /* Responsive styles */
  @media (min-width: 600px) {
    width: 250px; /* sm */
    margin-left: 10px; /* sm */
  }

  @media (min-width: 960px) {
    width: 300px; /* md */
    margin-left: 10px; /* md */
  }

  @media (min-width: 1280px) {
    width: 350px; /* lg */
    margin-left: 10px; /* lg */
  }
}

.view-dashboard {
  padding: 16px; /* Equivalent to p={2} in MUI Box */
  padding-top: 100px; /* Equivalent to pt={15} for xs devices */
  height: 81vh;
  overflow: auto;
}

@media (min-width: 600px) {
  .view-dashboard {
    padding-top: 2px; /* Equivalent to pt={2} for sm, md, lg devices */
  }
}


.daterange {
  display: flex;
  gap: 2px;
  padding: 5px 10px;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .daterange {
    max-width: 150px; /* Decrease maxWidth for smaller screens */
  }
}

.bottom-appbar-grid {
  /* spacing: 1; */
  justify-content: flex-start;
  padding-left: 2rem;
}

.bottom-appbar-button {
  margin: 0;
}

@media (max-width: 600px) {
  .bottom-appbar-grid {
    justify-content: flex-start;
    padding-left: 0;
  }
}

.adjustment-input {
  background: white;
  height: 30px;
}
@media (max-width: 600px) {
  .adjustment-input {
    width: 100px; /* Decreased width for mobile */
  }
}

@media (min-width: 601px) {
  .adjustment-input {
    width: 10px;
  }
}
.infoName {
  font-size: 18px; /* Default font size */
}

/* Style for text */
.infoText {
  font-size: 14px; /* Default font size */
}

/* Media query for screens smaller than or equal to sm breakpoint (600px wide) */
@media (max-width: 600px) {
  .infoName {
    font-size: 16px; /* Font size for smaller screens */
  }

  .infoText {
    font-size: 12px; /* Font size for smaller screens */
  }
}

.mobile-column-container {
  /* Default row layout */
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 600px) {
  /* Switch to column layout on smaller screens */
  .mobile-column-container {
      flex-direction: column;
      align-items: flex-start;
  }
}

.custom-class {
    background-color: #F9F9F9;
    padding-left: 0;
}

@media (min-width: 768px) { /* Assuming md breakpoint starts at 768px */
    .custom-class {
        padding-left: 16px; /* 4 * 4px (default spacing unit in many frameworks) */
    }
}

.custom-padding {
    padding-top: 80px; /* 10 * 4px (assuming default spacing unit of 4px) */
}

@media (min-width: 600px) { /* Assuming sm breakpoint starts at 600px */
    .custom-padding {
        padding-top: 8px; /* 2 * 4px (default spacing unit) */
        padding-left: 10px;
    }
}

/* Appbar.css */
.appBar {
  width: calc(100% - 72px + 1px); /* theme.spacing(9) = 72px */
  margin-left: calc(72px + 1px);  /* theme.spacing(9) = 72px */
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.title {
  padding-left: 48px; /* theme.spacing(6) = 48px */
}

@media (min-width: 960px) {
  .title {
    padding-left: 0;
  }
}

.box {
  display: flex;
  gap: 16px; /* theme.spacing(2) = 16px */
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-left: 8px; /* theme.spacing(1) = 8px */
  padding-right: 8px; /* theme.spacing(1) = 8px */
}

@media (min-width: 600px) {
  .box {
    padding-left: 16px; /* theme.spacing(2) = 16px */
    padding-right: 16px; /* theme.spacing(2) = 16px */
  }
}

.iconButton {
  margin-bottom: 0;
}

.userBox {
  display: none;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 600px) {
  .userBox {
    display: flex;
  }
}
.appbar-title-name {
  padding-left: 48px; /* Assuming theme.spacing(6) = 48px */
}
@media (min-width: 960px) {
  .appbar-title-name {
    padding-left: 0;
  }
}

/* Small (sm) and up: add additional styles if needed */
@media (min-width: 600px) {
  .appbar-title-name {
    padding-left: 0;
  }
}

/* Large (lg) and up: add additional styles if needed */
@media (min-width: 1280px) {
  .appbar-title-name {
    padding-left: 0;
  }
}



/* Default styles for Box component */
.box-container {
  display: flex;
  gap: 16px; /* Assuming gap={2} translates to 16px if 1 unit is 8px */
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-left: 8px; /* Assuming px={1} translates to 8px */
  padding-right: 8px;
}

/* Small (sm) and up: adjust padding */
@media (min-width: 600px) {
  .box-container {
    padding-left: 16px; /* Assuming px={2} translates to 16px */
    padding-right: 16px;
  }
}
.no-margin-bottom {
  margin-bottom: 0px;
}



/* Default styles for icon container */
.icon-container {
  display: flex;
  gap: 16px; /* Assuming gap={2} translates to 16px if 1 unit is 8px */
}

/* Styles for xs breakpoint */
@media (max-width: 599.95px) {
  .icon-container-xs {
    flex-direction: column;
  }
}

/* Styles for sm breakpoint */
@media (min-width: 600px) {
  .icon-container-sm {
    flex-direction: row;
  }
}


/* Default styles for userName container */
.userName {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for xs breakpoint */
@media (max-width: 599.95px) {
  .userName {
    display: none;
  }
}

/* Styles for sm breakpoint */
@media (min-width: 600px) {
  .userName {
    display: flex;
  }
}


/* styles.css */

/* Default styles for organization-info container */
.organization-info {
  display: flex;
  justify-content: space-between;
}

/* Responsive styles */
@media (min-width: 0px) and (max-width: 599.95px) {
  .organization-info {
    margin-top: 12px; /* Assuming mt={6} translates to 12px */
    align-items: center;
  }
}

@media (min-width: 600px) {
  .organization-info {
    margin-top: 0;
  }
}

@media (min-width: 1280px) {
  .organization-info {
    margin-top: 0;
  }
}

.customBox {
  padding: 12px 24px; /* Default padding for all screen sizes */
}

@media (min-width: 600px) {
  .customBox {
    padding-top: 0; /* Remove padding top for medium screens and above */
  }
}

@media (max-width: 599.95px) {
  .customBox {
    padding: 2px 4px; 
    padding-top: 150px; 
  }
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexGapContainer {
  display: flex;
  gap: 8px; /* Adjust gap as needed */
}


.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 16px; /* or use a value corresponding to your theme spacing */
}

@media (min-width: 960px) {
  .flex-container {
    flex-direction: row;
  }
}


.button-container {
  display: flex;
  flex-direction: column;
  gap: 16px; /* or use a value corresponding to your theme spacing */
}

@media (min-width: 960px) {
  .button-container {
    flex-direction: row;
  }
}

.kyb-padding-top {
  padding-top: 16px; /* Default padding top for larger devices */
}

.kyb-padding-top {
  padding-top: 16px; /* Default padding top for larger devices */
}

@media (min-width: 1024px) {
  .export-textalign {
    text-align: right;
    margin-top: -20px;
    margin-right: 5px;
    
  }
  .leads-card-container {
    padding-top: 20px; /* Adjust as needed for mobile screens */
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 1024px) {
  .kyb-padding-top {
    padding-top: 50px;
  }
  .password-card-container {
    padding-top: 20px; 
  } 
  .leads-card-container {
    padding-top: 120px; /* Adjust as needed for mobile screens */
  }
  .archive-box {
    margin-left: 100px;
    margin-top: 0px; /* mt={2} corresponds to 16px in default spacing */
    margin-bottom: 25px;
  }
  .upcoming-card-container {
    padding-top: 50px; /* Adjust as needed for mobile screens */
    }
    .box-content {
      flex-grow: 0;
    }
   
}
/* @media (min-width: 768px) {
  .udin-card-container {
    padding-top: 0px; 
  }
} */


@media (max-width: 768px) {
  .kyb-padding-top {
    padding-top: 50px; 
  }
  .password-card-container {
    padding-top: 50px; /* Adjust as needed for mobile screens */
  align-items: center;
  }
  .upcoming-card-container {
    padding-top: 110px; /* Adjust as needed for mobile screens */
    }
    .upcoming-margin{
      margin-bottom: 60px;

    }
  .archive-box {
    margin-left: 20px;
    margin-top: 0px; /* mt={2} corresponds to 16px in default spacing */
    margin-bottom: 25px;
  }

  .export-textalign {
    text-align: center;
    margin-top: -20px;
    margin-right: 5px;
    
  }
  .leads-card-container {
    padding-top: 80px; /* Adjust as needed for mobile screens */
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .mobileColumn {
    flex-direction: column;
    align-items: center;
  }
  .search-filter-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .box-content {
    flex-grow: 0;
  }
}

@media (max-width: 480px) {
  .kyb-padding-top {
    padding-top: 100px; 
  }
  .password-card-container {
    padding-top: 100px; /* Adjust as needed for mobile screens */
  }
  .leads-card-container {
    padding-top: 80px; /* Adjust as needed for mobile screens */
    padding-left: 16px;
    padding-right: 16px;
  }
  .box-content {
    flex-grow: 0;
  }
  .export-textalign {
    text-align: center;
    margin-top: -20px;
    margin-right: 5px;
    
  }
}

@media (max-width: 320px) {
  .icon-button {
    display: inline-flex;
    margin-left: 1px;
  }
  .kyb-padding-top {
    padding-top: 150px;
  }
  .password-card-container {
    padding-top: 50px; /* Adjust as needed for mobile screens */
  }
  .leads-card-container {
    padding-top: 80px; /* Adjust as needed for mobile screens */
  }
  .box-content {
    flex-grow: 1;
  }
  .export-textalign {
    text-align: center;
    margin-top: -20px;
    margin-right: 5px;
    
  }
}



/* For larger screens */
.search-filter-box {
  display: flex;
  justify-content: center;
  gap: 16px;
}
/* password card container */
.menu-icon-grid {
  display: none; /* Hide by default */
}

@media (min-width: 480px) {
  .menu-icon-grid {
    display: flex; /* Show on medium screens and above */
    justify-content: flex-end;
  }
  
}
.typography-body1 {
  color: #1976d2; /* Primary color */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.password-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* Corresponds to borderRadius: 2 (2 * 4px = 8px) */
  padding: 16px; /* Corresponds to p: 2 (2 * 8px = 16px) */
  margin-bottom: 32px; /* Corresponds to mb: 4 (4 * 8px = 32px) */
  overflow: hidden;
}
.grid-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; /* Corresponds to xs: 2 (2 * 8px = 16px) */
  overflow: hidden;
}
.box-content {
  min-width: 0;
}
@media (min-width: 600px) {
  .grid-item {
    justify-content: flex-start;
    margin-bottom: 0;
  }
}
.tasktype-box {
  text-align: right;
}

@media (max-width: 599.95px) { /* xs */
  .tasktype-box {
    margin-top: 0;
    margin-right: 0;
    text-align: center;

  }
}

@media (min-width: 600px) and (max-width: 959.95px) { /* sm */
  .tasktype-box {
    margin-top: -20px;
    margin-right: 5px;
  }
}

@media (min-width: 960px) and (max-width: 1279.95px) { /* md */
  .tasktype-box {
    margin-top: -20px;
    margin-right: 5px;
  }
}

@media (min-width: 1280px) { /* lg */
  .tasktype-box {
    margin-top: -20px;
    margin-right: 5px;
  }
}

/* Cloud Storage Nav Styling */
.cloudNav {
  display: none; /* Default for xs (extra-small screens) */
}

@media (min-width: 600px) {
  .cloudNav {
    display: block; /* For sm (small screens and up) */
  }
}

@media (min-width: 900px) {
  .cloudNav {
    display: block; /* For md (medium screens and up) */
  }
}

@media (min-width: 1200px) {
  .cloudNav {
    display: block; /* For lg (large screens and up) */
  }
}

.quantumPagePadding {
  padding: 16px; /* for xs */
  padding-top: 70px;
}

@media (min-width: 600px) {
  .quantumPagePadding {
      padding: 20px; /* for sm */
  }
}

@media (min-width: 960px) {
  .quantumPagePadding {
      padding: 32px; /* for md */
  }
}

@media (min-width: 1280px) {
  .quantumPagePadding {
      padding: 40px; /* for lg */
  }
}

.attachmentsContainer {
  padding-left: 32px; /* Default padding (px={4}) */
  padding-right: 32px; /* Default padding (px={4}) */
  padding-bottom: 16px; /* Default padding (py={2}) */
}

@media (max-width: 600px) {
  .attachmentsContainer {
      padding-top: 110px; /* Add padding top for xs screens */
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .attachmentsContainer {
      padding-left: 24px; /* Adjust padding for sm screens */
      padding-right: 24px; /* Adjust padding for sm screens */
      padding-top: 24px; /* Adjust padding top for sm screens */
      padding-bottom: 24px; /* Adjust padding bottom for sm screens */
  }
}

@media (min-width: 960px) and (max-width: 1280px) {
  .attachmentsContainer {
      padding-left: 32px; /* Adjust padding for md screens */
      padding-right: 32px; /* Adjust padding for md screens */
      padding-top: 32px; /* Adjust padding top for md screens */
      padding-bottom: 32px; /* Adjust padding bottom for md screens */
  }
}

@media (min-width: 1280px) {
  .attachmentsContainer {
      padding-left: 40px; /* Adjust padding for lg screens */
      padding-right: 40px; /* Adjust padding for lg screens */
      padding-top: 40px; /* Adjust padding top for lg screens */
      padding-bottom: 40px; /* Adjust padding bottom for lg screens */
  }
}
